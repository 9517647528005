<template>
  <div class="row align-items-center h-100 tracker-show">
    <div class="col-12">
      <div v-if="tracker != null" class="tracker-infos text-center">
        <img class="d-none d-sm-inline w-75" :src="tracker | image_device_type" />

        <div class="tracker-name">
          {{ tracker.name }}
        </div>

        <div v-if="recurringSubscription" class="subscription-type">
          <SubscriptionCurrentType :tracker="tracker" :current="recurringSubscription"/>
        </div>

        <SubscriptionRemainingDays :tracker="tracker" :recurring="recurringSubscription"
                                   class="d-block d-sm-none"/>

        <img class="d-inline d-sm-none tracker-img-width" :src="tracker | image_device_type" />

        <div v-if="tracker.tracker_status.sub_state != 'not_activated'" class="tracker-activity">
          <span v-if="
            new Date(tracker.created) >
            new Date('01/01/2017')
          ">
            {{ isSDC ? $t("active_collar_since") : $t("online_since") }}: <br />
            {{ tracker.created | formatDateDDMMYYYY }}
          </span>
        </div>
        <div class="tracker-serial">
          {{ $t("serial_number") }} : {{ tracker.serial }}
        </div>

        <a v-if="isSDC && tracker.tracker_status.sub_state != 'normal' && noSubscriptions && !getLoading"
          :href="LinkToSubscriptionPage">
          <b-button class="sub-button d-block d-sm-none subscription-button" block variant="primary"
            v-on:click="setSelectedDevice(tracker)">
            <div class="white">
              {{ $t("choose_subscription") }}
            </div>
          </b-button>
        </a>

        <router-link v-if="isSDC && tracker.tracker_status.sub_state != 'normal' && noSubscriptions && !getLoading" to="/link_subscription">
          <b-button class="sub-button d-block d-sm-none light-subscription-button" block variant="primary"
            v-on:click="setSelectedDevice(tracker)">
            <div class="text-black">
              {{ $t("link_subscription") }}
            </div>
          </b-button>
        </router-link>
        <div>
        <div v-if="recurringSubscription">
          <b-button class="sub-button d-block d-sm-none subscription-button" block variant="primary"
            v-on:click="goToStripePortal()">
            <div class="text-white">
              {{ $t("payment_portal_link") }}
            </div>
          </b-button>
        </div>
        <router-link v-else-if="!getLoading" :is="noSubscriptions ? 'div' : 'router-link'" to="/SubscriptionSelector">
          <b-button class="sub-button d-block d-sm-none subscription-button" block :disabled="noSubscriptions"
                    variant="primary" v-on:click="setSelectedDevice(tracker)">
            <div v-if="noSubscriptions" class="text-center d-block d-sm-none">
              {{ $t("no_subscriptions") }}
            </div>
            <div v-else class="text-white">
              {{ subscriptionMessage }}
            </div>
          </b-button>
        </router-link>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionRemainingDays from "@/components/subscription/SubscriptionRemainingDays";
import SubscriptionCurrentType   from "../subscription/SubscriptionCurrentType.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import { getStripePortal } from "../../api/devices.api"
import {redirectLocaleWeb} from "../../international";

export default {
  name: "TrackerShow",
  components: {
    SubscriptionRemainingDays,
    SubscriptionCurrentType
  },

  data() {
    return {
      SubscriptionType: null
    }
  },

  props: {
    tracker: null,
    trackerSubscriptions: null,
  },

  methods: {
    ...mapActions("devices", ["setSelectedDevice"]),
    async goToStripePortal() {
      const current_route = this.$router.currentRoute;
      const portalUrl = await getStripePortal(current_route.path + '?serial=' + this.tracker.serial);
      window.location.href = portalUrl.data;
    }
  },
  mounted(){
    //console.log('mounted tracker show');
  },
  computed: {
    ...mapGetters("users", ["getUserName"]),
    ...mapGetters("subscriptions", ["getSubscriptions", "getLoading"]),
    recurringSubscription() {
      let res=null;
      let subscriptions = this.trackerSubscriptions;
      if (this.trackerSubscriptions) {
        this.trackerSubscriptions.forEach(item => {
          if (item.serial == this.tracker.serial) {
            if (item.current) {
              item.current.update({'status': 'active'})
              res = {...item.current}
            } else if (item.current_subscription) {
              res = {...item.current_subscription};
            }
          }
        });
      }
      console.log('recurringSubscription ' + this.tracker.serial, res);
      return res;
    },
    subscriptionMessage() {
      let state = this.tracker.tracker_status.sub_state;
      if (state == 'not_activated') {
        return this.$i18n.t('subscribe')
      } else if (state == 'normal') {
        return this.$i18n.t("extend")
      } else {
        return this.$i18n.t("renew")
      }
    },
    LinkToSubscriptionPage() {
      return 'https://petcare.invoxia.com/' + this.redirectCountryLanguage + '/subscription' + '?email=' + encodeURIComponent(this.getUserName)
    },
    redirectCountryLanguage() {
      return redirectLocaleWeb();
    },
    noSubscriptions() {
      if (
        this.getSubscriptionsByDeviceType &&
        this.getSubscriptionsByDeviceType.length
      ) {
        return false;
      } else {
        return true;
      }
    },
    isSDC() {
      return this.$props.tracker.type == "tracker_04" || this.$props.tracker.type == "tracker_06";
    },
    getSubscriptionsByDeviceType() {
      let subscriptionsByDeviceType = this.getSubscriptions.find(
        (subscriptionByTrackers) => {
          return subscriptionByTrackers.serial == this.$props.tracker.serial;
        }
      );
      if (subscriptionsByDeviceType) {
        return subscriptionsByDeviceType.prices;
      } else {
        return null;
      }
    },

  },

};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.tracker-show {
  width: 100vw;

  @media screen and (min-width: 768px) {
    width: auto;
  }
}

.tracker-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
}

.tracker-activity {
  font-size: 15px;
  color: #6c757d;
  margin: 15px 25px;
}

.tracker-serial {
  font-size: 10px;
  color: #6c757d;
  margin: 10px 20px;
}

.tracker-img-width {
  width: 25vh !important;
}

.light-subscription-button {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  border-radius: 18px;
  padding: 18px;


  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* Color-primary/200 */

  background: #F1F8FD;
  /* Color-neutral-shades/200 */

  border: 2px solid #F0F0F0;
  border-radius: 18px;

  color: #3B3B3B;
}

.subscription-button {
  background: radial-gradient(135.75% 139.94% at 0% 50%, #418FDE 0%, #1D60E0 43.64%);
  border-color: transparent;
  border-radius: 18px;
  padding: 18px;
  box-shadow: 0px 5px #174396;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  display: flex;
  align-items: center;

  color: #F1F8FD;
}

.subscription-type {
  font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #1D60E0;
    padding-top: 1em;
    padding-bottom: 1em;
}
</style>